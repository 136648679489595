<template>
    <div class="page-404">
        <h3 class="title">404</h3>
        <p class="info">抱歉，您访问的页面不存在</p>
        <a-button class="btn" type="primary" @click="toHome">回到首页</a-button>
        <mp-icon icon="ic-app-permissions" class="class-name act" test="www" />
    </div>
</template>

<script>
import MpIcon from '@/components/MpIcon'
export default {
    components: {
        MpIcon
    },
    name: 'page-404',
    data() {
        return {

        }
    },
    methods: {
        toHome() {
            this.$router.replace('/')
        }
    }
}
</script>

<style lang="less" scoped>
.page-404 {
    padding: 100px 0; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
        margin: 24px 0;
        font-weight: 400;
        font-size: 48px;
        color: #999;
    }
    .info {
        font-size: 14px;
        color: #666;
    }
    .btn {
        margin-top: 48px;
    }
}
</style>